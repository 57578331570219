.work-items {
  width: 50%;
  padding: 2em;
  align-self: center;
}

.work-link {
  font-family: "Bungee Shade", cursive;
  position: relative;
  z-index: 100;
  display: block;
  color: #1a1919;
  font-size: 6.5vw;
  line-height: 1em;
  font-weight: 300;
  text-align: left;
  text-decoration: none;
  margin-bottom: 2rem;
}
.info-and-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info-section {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 2em;
  font-weight: 400;
  position: relative;
  width: 50%;
  padding: 3em;
  align-self: center;
}

p {
  letter-spacing: 0.5px;
}

.nav-left {
  display: flex;
  width: 6%;
  height: 100vh;
  padding-top: 5%;
  padding-bottom: 30px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.nav-line {
  width: 1px;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 80px;
  background-color: #1a1919;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

.left-link {
  flex: 0 auto;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 15px;
  transform: rotate(-90deg);
}
@media screen and (max-width: 700px) {
  .info-and-nav {
    flex-direction: column;
    justify-content: center;
  }
}
