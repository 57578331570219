.about-title {
  font-family: "Bungee Shade", cursive;
  position: relative;
  z-index: 100;
  display: block;
  color: #1a1919;
  font-size: 8vw;
  line-height: 1em;
  font-weight: 300;
  text-align: left;
  text-decoration: none;
  margin-bottom: 2rem;
  z-index: 100;
}
.links{
  text-decoration: underline !important;
}

.profile-image {
  width: 50%;
  margin-top: 20%;
  margin-right: -50%;
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}
.about-section {
  width: 100%;
  height: auto;
  max-width: 768px;
  padding-top: 10%;
  padding-bottom: 12%;
  position: relative;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
}

.quote {
  padding-bottom: 2%;
  font-family: Grandslang roman, sans-serif;
  font-size: 46px;
  font-weight: 300;
  line-height: 1.3em;
  display: block;
  position: relative;
}

.paragraph {
  position: relative;
}

.button {
  color: #ece7e1;
  letter-spacing: 1px;
  background-color: #1a1818;
  border-radius: 50px;
  padding: 20px 60px;
  font-family: Grandslang roman, sans-serif;
  font-size: 24px;
  font-weight: 400;
  transition: padding 0.25s ease-in-out;
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

@media screen and (max-width: 700px) {
  .profile-image {
    width: 35%;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
    margin-top: 1%;
    margin-right: 0;
    opacity: 70%;
  }
}
